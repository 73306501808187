import React from 'react';
import authRoles from '../../auth/authRoles';

const Integrations = React.lazy(() => import('./Integrations'));
const Integration = React.lazy(() => import('./integration/Integration'));

const IntegrationsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.ameroAdmin,
	routes: [
		{
			path: '/integrations',
			element: <Integrations />
		},
		{
			path: '/integrations/:integrationId',
			element: <Integration />
		}
	]
};

export default IntegrationsAppConfig;
