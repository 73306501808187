import React from 'react';
import authRoles from '../../auth/authRoles';

const MyIntegrations = React.lazy(() => import('./MyIntegrations'));

const MyIntegrationsAppConfig = {
	settings: {
		layout: {}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/my-integrations',
			element: <MyIntegrations />
		}
	]
};

export default MyIntegrationsAppConfig;
