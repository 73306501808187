import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RedirectBasedAuth() {
	const user = useSelector(({ auth }) => auth.user);

	if (user && user.role && user.role.includes('ameroAdmin')) {
		return <Navigate to="organisations" />;
	} else {
		return <Navigate to="integrations" />;
	}
}

export default RedirectBasedAuth;
