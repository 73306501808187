import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import OrganisationsAppConfig from '@ameroservices-platform/integrationengine-backend/app/main/organisations/OrganisationsAppConfig';
import Error404PageConfig from '@ameroservices-platform/integrationengine-backend/app/main/error/404/Error404PageConfig';
import Error500PageConfig from '@ameroservices-platform/integrationengine-backend/app/main/error/500/Error500PageConfig';
import LoginConfig from '@ameroservices-platform/integrationengine-backend/app/main/login/LoginConfig';
import LogoutConfig from '@ameroservices-platform/integrationengine-backend/app/main/logout/LogoutConfig';
import FuseLoading from '@ameroservices-platform/integrationengine-backend/fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import authRoles from '@ameroservices-platform/integrationengine-backend/app/auth/authRoles';
import EventAppConfig from '@ameroservices-platform/integrationengine-backend/app/main/event/EventAppConfig';
import RedirectBasedAuth from '@ameroservices-platform/integrationengine-backend/app/ui-components/RedirectBasedAuth';
import MyIntegrationsAppConfig from '@ameroservices-platform/integrationengine-backend/app/main/myIntegrations/MyIntegrationsAppConfig';
import IntegrationsAppConfig from '@ameroservices-platform/integrationengine-backend/app/main/integrations/IntegrationsAppConfig';

const routeConfigs = [
	OrganisationsAppConfig,
	IntegrationsAppConfig,
	MyIntegrationsAppConfig,
	Error404PageConfig,
	Error500PageConfig,
	EventAppConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, authRoles.user),
	...FuseUtils.generateRoutesFromConfigs([LogoutConfig, LoginConfig]),
	{
		path: '/',
		element: <RedirectBasedAuth />
	},
	{
		path: 'loading',
		element: <FuseLoading />
	},
	{
		path: '*',
		element: <Navigate to="/error/404" />
	}
];

export default routes;
