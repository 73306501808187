import FuseNavigation from '@ameroservices-platform/integrationengine-backend/fuse/core/FuseNavigation';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from '@ameroservices-platform/integrationengine-backend/app/store/fuse/navigationSlice';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { navbarCloseMobile } from '../../store/fuse/navbarSlice';

function Navigation(props) {
	const organisationId = firebaseService.getOrganisationId();
	const navigation = useSelector(selectNavigation);
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
	const dispatch = useDispatch();
	const [organisation, setOrganisation] = React.useState();
	const role = useSelector(({ auth }) => (auth.user.claims ? auth.user.claims.role : 'user'));

	React.useEffect(() => {
		if (role === 'ameroAdmin') {
			firebaseService.getOrganisationRootDB().onSnapshot((doc) => {
				setOrganisation(doc.data());
			});
		}
	}, [role, organisationId]);

	function handleItemClick(item) {
		if (mdDown) {
			dispatch(navbarCloseMobile());
		}
	}

	const filteredNavigation = useMemo(() => {
		return navigation
			.filter((navRow) => {
				return !(navRow.requireOrganisation && !organisationId);
			})
			.map((navRow) => {
				if (organisation && organisation.name && navRow.title === 'Din Organisation') {
					navRow.title = organisation.name;
				}
				return navRow;
			});
	}, [navigation, organisationId, organisation]);

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={filteredNavigation}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
			onItemClick={handleItemClick}
		/>
	);
}

Navigation.defaultProps = {
	layout: 'vertical',
};

export default memo(Navigation);
