import React, { lazy } from 'react';
import authRoles from '../../auth/authRoles';

const Event = lazy(() => import('./Event'));

const EventAppConfig = {
	settings: {
		layout: {},
	},
	routes: [
		{
			path: '/events/:integrationType/:integrationId/:eventId',
			element: <Event />,
		},
	],
};

export default EventAppConfig;
