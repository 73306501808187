import authRoles from '../auth/authRoles';

const navigationConfig = [
	{
		id: 'ameroAdmin',
		title: 'Amero Admin',
		type: 'group',
		icon: 'apps',
		auth: authRoles.ameroAdmin,
		children: [
			{
				id: 'organisations',
				title: 'Organisationer',
				type: 'item',
				icon: 'business',
				auth: authRoles.ameroAdmin,
				url: '/organisations',
				exact: true
			},
			{
				id: 'integrations',
				title: 'Alle Integrationer',
				type: 'item',
				icon: 'cached',
				auth: authRoles.ameroAdmin,
				url: '/integrations',
				exact: true
			}
		]
	},
	{
		id: 'organisation',
		title: 'Din Organisation',
		type: 'group',
		icon: 'apps',
		requireOrganisation: true,
		children: [
			{
				id: 'mineIntegrations',
				title: 'Mine Integrationer',
				type: 'item',
				icon: 'cached',
				url: '/my-integrations',
				exact: true,
				requireOrganisation: true
			}
		]
	}
];

export default navigationConfig;
